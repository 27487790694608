.terms-container {
    padding: 20px;
    margin-top: 1rem;
    margin-left: 1rem;
  }
/*   
  h1, h2, h3 {
    color: #0056b3;
  } */
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  h3 {
    margin-top: 10px;
    font-weight: normal;
  }
  
  p, ul {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {
    .terms-container {
      padding: 15px;
    }
  }
  