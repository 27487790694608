#dollar {
  font-size: calc(47em / 100);
  top: -0.8em;
}
#month {
  color: #555555;
  font-size: 17px;
  top: -0.8em;
}
#buy-now {
  background-color: #ff0000;
  color: white;
}
#payment-button {
  /* background-color: #FF0000; */
  color: white;
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#submit-button {
  background-color: #ff0000;
  color: white;
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#back-button {
  color: #ff0000;
  border: 2px solid #ff0000;
  background-color: white;
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#validate-button {
  background-color: #61c961;
  color: white;
  margin-left: 7%;
}
.switch {
  position: relative;
  display: inline-block;
  width: 69px;
  height: 25px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #ff0000;
}
input:focus + .slider {
  box-shadow: 0 0 0px #ff0000;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
#header-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hr-line {
  width: 8rem;
  border-top: 2px solid #ff0000;
}
#list-line {
  color: #ddd;
  border-top: 2px solid;
}
.plan-title {
  margin-top: 2%;
  margin-bottom: 1%;
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
}
#essential-plan-title,
#ultimate-plan-title,
#enterprise-plan-title {
  line-height: 1.2;
  font-size: 24px;
  font-weight: 600;
}
#signup-container {
  background-color: white;
  overflow: hidden;
  display: flex;
}
#form-container {
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border: 2px solid #6666660f;
  padding: 20px;
}
#form-container,
#contact-page {
  margin-top: 1rem;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* padding/: 20px; */
}
.image-container {
  background-color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.form-control {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #66666654 !important;
}
.btn-primary {
  color: #ff0000;
  border: 2px solid #ff0000;
  background-color: white;
}
.btn-primary:hover {
  color: #ff0000;
  border-color: #ff0000;
  background-color: white;
}
.required {
  color: #ff0000;
}
#input-group-text {
  background-color: white !important;
}
.input-group-text {
  background-color: white !important;
  border: 1px solid #00000033 !important;
}
#input-group button {
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
}
#input-group button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
#input-group input {
  text-align: center;
  border: 1px solid #ccc;
  width: 28px;
  background-color: white;
}
#cart-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
#planTitle {
  font-size: large;
}
#back-btn {
  font-weight: 600;
  font-size: 22px;
  border: 1px solid;
  padding: 0.3rem;
  border-radius: 5px;
}
.iti__country-list {
  width: 14rem !important;
  overflow: auto;
  scrollbar-width: thin;
}

.input-group-prepend {
  display: flex;
}

#input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  
}

#conNum{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.custom-toast {
  width: 330px !important; /* Adjust the width as needed */
}