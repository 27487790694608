@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

*{
  font-family: "Figtree";
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a7a7a6;
    border-radius: 100px;
  }
  .login-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px;
  }
  .login-form-container h2{
    color: #ff0000;
    font-weight: 800;
  }
  .input-with-border {
    width: 420px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #ccc; /* Border only at the bottom */
    outline: none;
  }
  .profile-page-container {
    margin-bottom: 50px;
    margin-top: 50px;
}
  .login-input{
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .login-input img {
    width: 20px;
    height: 20px;
    margin-top: 13px;
  }
  .form-button{
    display: flex;
    gap: 323px;
    align-items: center;
  }
  .login-btn{
    background-color: #ff0000 !important;
    color: white !important;
    width: 7rem;
    border: none !important;
  }
  .form-button .forgot-btn {
    color: #ff0000;
    font-weight: bold;
    text-decoration:none;
    cursor: pointer;
  }
  .form-inputs{
    display: flex;
    flex-direction: column;
  }
  .form-inputs span {
    margin-top: 5px;
  }
  .left-img{
    display: flex;
    justify-content: center;
  }
  /* self service redisign */
  .breadcrumb-home{
    color: #ff0000;
    font-weight: 600;
  }
  .breadcrumb-active{
    font-weight: 600 !important;
  }
  .breadcrumb-contain{
    margin-top: 10px !important;
    margin-bottom: 50px;
  }
  .breadcrumb-contain .nav-profile {
    display: flex;
    justify-content: end;
  }
  .nav-profile img {
    width: 25px;
    height: 25px;
    background: #dfd3d3;
    border-radius: 50%;
    padding: 2px;
  }
  .input-serach{
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .input-serach input {
    width: 537px;
    margin-left: 72px;
  }
  .input-serach button {
    border: none;
    position: absolute;
    margin-right: 13px;
    background: none;
  }
  .ticket-table-nav .ticket-count{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 30px;
  }
  .ticket-table-nav .ticket-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
  }
  .ticket-buttons .search-input{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticket-buttons .search-input button {
    border: none;
    background: none;
    position: absolute;
    margin-left: 158px;
  }
  .ticket-buttons .search-input button{
    color: #878787;
    margin-top: 3px;
  }    
  .ticket-buttons .new-ticket{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dot {
    height: 7px;
    width: 7px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }
  .new-ticket button {
    color: #878787;
  }
  .new-ticket button:hover {
    color: #878787 !important;
  }

  /* Meadia */
  

  @media (min-width: 345px) and (max-width: 411px){ 
    /* max-width: 576px */
    .left-img img{
      width: 100%;
    }
    .input-with-border{
      width: 300px;
    }
    .form-button {
      gap: 73px;
    }
    .ticket-table-nav .ticket-count{
      justify-content: space-between;
      font-size: small;
      margin-bottom: 10px; 
  }
  .ticket-table-nav .ticket-buttons{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .ticket-buttons .new-ticket {
    margin-left: -154px;
    width: 140px;
  }
  .ticket-buttons .filter-btn {
    margin-left: 154px;
    margin-top: -45px;
    width: 140px !important;
  }
  .search-input{
    width: 294px;
  }
  .ticket-buttons .search-input button{
    margin-left: 250px;
  }
  .personal-info{
    font-size: medium !important;    
  }
  .expiry-package {
    display: flex !important;
    justify-content: space-between !important;
  }

  .current-package {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    font-size: small !important;
  }
  .footer-contain img{
      padding-left: 0px !important;
  }
  .footer-contain .Footer-Content {
    padding-left: 0px !important;
  }

  }

  @media (min-width: 412px) and (max-width: 540px) {
    /* (min-width: 412px) and (max-width: 540px) */
    .search-input{
              width: 317px;
    }
    .ticket-buttons .new-ticket {
      margin-left: -165px;
      width: 150px;
  }
  .ticket-buttons .filter-btn {
    margin-left: 180px;
    margin-top: -45px;
    width: 150px !important;
  }
  .footer-contain img{
    padding-left: 0px !important;
  }
  .footer-contain .Footer-Content {
  padding-left: 0px !important;
  }
  .left-img img {
    width: 100%;
  }
  .input-with-border{
    width: 357px;
  }
  .form-button{
    gap: 122px;
  }
  .ticket-table-nav .ticket-count{
    justify-content: space-between;
    font-size: large;
    margin-bottom: 10px; 
  }
  .ticket-table-nav .ticket-buttons{
    justify-content: center;
    flex-direction: column;
  }
  .ticket-buttons .search-input button{
    margin-top: 0px;
    margin-left: 270px;
  }
  .expiry-package {
    justify-content: space-between !important;
  }
  .current-package{
    justify-content: space-between !important;
  }
  }


  @media (min-width: 541px) and (max-width: 576px) {
    .search-input{
      width: 463px;
    }
    .ticket-buttons .search-input button {
      margin-left: 415px;
  }
    .ticket-buttons .new-ticket {
      margin-left: -246px;
      width: 214px !important;
  }
  .new-ticket button {
    width: 12.9rem !important;
  }
  .ticket-buttons .filter-btn {
    margin-left: 256px;
    margin-top: -45px;
    width: 199px !important;
  }
  .ticket-table-nav .ticket-buttons{
    flex-direction: column;
  }
  }

  @media (min-width: 577px) and (max-width: 767px) {
    .ticket-table-nav .ticket-buttons{
      justify-content: space-between;
  }
  .ticket-table-nav .ticket-count{
      justify-content: space-between;
      margin-bottom: 6px;
  }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    
    .input-with-border {
      width: 305px;
    }
    .form-button {
      gap: 76px;
    }
    .expiry-package {
      display: flex !important;
      justify-content: space-between !important;
    }
    
    .current-package {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      font-size: small !important;
    }
    .left-img img {
      width: 100%;
    }
  }
  /* my account page re design */
  .myaccount-container{
    margin-top: 25px !important;
    background: #FAFAFA;
    border-radius: 10px;
    box-shadow: 1px 2px 1px 2.4px #00000040;
  }
  .profile-card-image{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .profile-card-image .profile-img{
    width: 123px;
    height: 131px;
    padding: 22px;
    background: #D9D9D9;
    border-radius: 50%;
  }
  .personal-info {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    word-wrap: break-word;
  }
  .personal-info-line{
    margin-top: 7px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-weight: 700 !important;
  }
  .personal-info-line img {
    /* font-weight: 700; */
  }
  .pers-info-inner{
    display: flex !important;
    align-items: center;
    margin-top: 10px !important;
  }
  .profile-upload-img{
    position: relative;
    margin-top: 104px;
    width: 18px;
    margin-left: -22px;
    cursor: pointer;
  }
  .myaccount-feature-container{
    margin-top: 25px !important;
    background: #FAFAFA;
    border-radius: 10px;
    box-shadow: 1px 2px 1px 2.4px #00000040
  }
  .current-package{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .expiry-package{
    display: flex;
    justify-content: end;
    gap: 25px;
  }
  .expiry-package .active-package{
    color: red;
    font-weight: 700;
  }
  .current-package .active-package{
    color: red;
    font-weight: 700;
  }
  .plan-details{
    box-shadow: 1px 2px 1px 2.4px #00000040;
    padding: 10px;
    border-radius: 5px;
  }
  .plan-feature{
    margin: 10px !important;
  }
  .plan-feature .feature{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }
  .plan-feature .feature img {
    width: 20px;
  }
  .plan-details-footer{
    display: flex;
    justify-content: end;
  }
  .plan-details-footer .renew-btn{
    text-transform: capitalize;
    background: red;
    border-radius: 5px;
  }
  .tss-1937jnc-MUIDataTableHead-main{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .accordion-header  {
    margin-top: 0px;
  }
.plan-tab{
  color: red !important;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: red !important;
}
.tss-178gktx-MUIDataTableHeadCell-contentWrapper{
  justify-content: center;
}
