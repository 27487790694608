.status {
    width: 100%;
    white-space: nowrap;
    color: white;
    height: 42px;
    border: none;
  }

  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table th,
  .table td {
    padding: 8px;
    vertical-align: middle;
  }
  
  .table th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #e9ecef;
  }
  
  .table thead th {
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody td a {
    color: blue;
    text-decoration: none;
  }
  
  .table tbody td a:hover {
    text-decoration: underline;
  }
  
  .table .status {
    text-transform: capitalize;
    border-radius: 4px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  /* tbody tr, tbody td {
    background-color: #80808012 !important;
    border-top: 5px solid white !important;
    border-bottom: 5px solid white !important;
  } */

  thead th, thead tr {
    background-color: white !important;
  }
  
  thead {
    border-bottom: 5px solid white;
  }
  
  tbody tr td {
    text-align: center;
  }
  
  table td,
  table th {
    vertical-align: middle !important;
  }
  
  table td {
    padding: 0.45rem !important;
  }
  

  #tableContent {
    display: flex;
    justify-content: center;
    overflow-y: auto;
  }

  #login-container {
    margin-top:8.7%;
    margin-bottom: 5%;
  }

  #login-button {
    color: #ff0000;
    border: 2px solid #ff0000;
    background-color: white;
    width: 27%;
  }

  .user-tickets-table-button {
    color: #000 !important;
    background-color: #e1e3ff00 !important;
    border: none;
  }

  #user-button{ 
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }

  /* .popup {
    display: block;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  } */

  .popup-content {
    width: 85%;
margin: 10% auto;
background-color: #fff;
padding: 20px;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: auto; 
max-height: 100%; 
position: relative;
  }

  .modal-dialog {
    max-width: none;
  }

  .desc {
    flex: 1;
    margin-left: 3%;
    max-height: 6em;
  }
  .scrollable-text {
max-height: 6em;
overflow: auto;
}

@media (max-width: 768px) {
.popup-content {
  width: 90%;
}
}

  #addTicketPopup {
    display: block;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1;
  }
  .modal-dialog {
    max-width: none;
  }

  #modalheader {
  display: flex;
  margin-left: auto;
  color: red;
  background: none;
  border: none;
  height: 0px;
  }

  .form-controls {
    width: 200%;
  }

  #TicketSeverity .form-select {
    width: 100%;
    max-height: 50px;
    overflow: auto;
  }

  #options{
    width: 100%;
    height: 40px;
  }

  .sticky-footer {
display: flex;
justify-content: flex-end;
position: sticky;
bottom: 0;
background-color: white;
z-index: 1;
margin-bottom: auto;
}
.btn-groups{
    right: 20px;
    display: flex;
    justify-content: flex-end;
    margin-top: 3%;
}

  @media (max-width: 768px) {
    .popup {
      width: 80% !important;
    }
  }


  .btn-groups2 {
    display: flex;
    justify-content: flex-end;
    bottom: 20px;
    width: calc(100% - 40px);
  }

  #tooltip{
  font-style: italic;
  color: red;
  background-color: none;
  background-color: #f4f4f4;
  border-radius: 137%;
  width: 6%;
  height: 25px;
  padding: inherit;
  }
  .resolution-popup {
position: fixed;
top: 45%;
left:40%;
background-color: white;
padding: 20px;
border: 1px solid #ccc;
z-index: 1000;
}

.comment-input {
width: 100%;
margin-bottom: 10px;
}

.submit-button, .close-button {
padding: 10px;
margin-right: 10px;
}

.cancel-option{
background-color: red !important;
color: white !important;
}

#sortSelect{
border-radius: 5px;
border: 1px solid #ccc;
background-color: #f8f9fa;
cursor: pointer;
}

/* Add your custom styles here */
.popup {
  position: fixed;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -20%);
  width: 40%;
  z-index: 1050;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.popup .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.severity{
  display: flex;
  align-items: center;
  gap: 1rem;
}

#severity{
  width: 8rem
}

.custom-tooltip .tooltip-inner {
  max-width: 200px; /* Adjust the width as needed */
  white-space: pre-wrap; /* This ensures that the text will wrap */
}


.add-btn{
  border: 1px solid #ccc;
  cursor: pointer;
}

.add-btn:hover{
 background-color: #f8f9fa !important;
}

.Button-grps{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.details-popup{
  border-radius: 5px;
  border: 1px solid #ccc;
}

.closeDetials{
  cursor: pointer;
  color:black;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #a7a7a6;
  border-radius: 100px;
}

.Pagination{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 

#table-td-data{
  vertical-align: baseline !important;
}

#commentsTableContainer{
  max-width: 100%;
  overflow: auto;
}
/* self service re disign */
.table thead {
  border: 2px solid #dee2e6;
}
.table tbody tr {
  border: 2px solid #dee2e6;
}
tbody:before {
  content: "-";
  display: block;
  line-height: 1em;
  color: transparent;
}
.table-item-select{
  border: 1px solid #ff0000;
}
.pagination .page-link {
  
  color: red !important;
  background-color: #fff !important;
}
.pagination .active .page-link {
  border-color:  red !important;
  border-radius: 5px;
}
.pagination .disabled .page-link {
  border:none;
}
.table-bordered td, .table-bordered th{
  border: none;
  }
  .table-bordered {
    border: none;
}
.severity .severity-select{
  gap: 10px;
  display: flex;
  align-items: baseline;
}