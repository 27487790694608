.Header-Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: white;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
  }
  
  .Logo-Part {
    cursor: pointer;
  }
  
  .Content-Part {
    display: flex;
    gap: 2.2em;
    cursor: pointer;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
  }
  
  .Content-Part .active {
    color: red;
  }
  
  #contact-btn {
    background-color: #ff0000 !important;
    color: white !important;
    width: 7rem;
    border: none !important;
  }

  
  @media (max-width: 992px) {
    .Content-Part {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5em;
      padding: 1em;
    }
  }
  
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
    color: #ff0000 !important;
}

.nav-link{
    color: #00023c !important;
}

.nav-link:hover{
    color: #ff0000 !important;
}

.navbar-brand{
  margin-left: 1% !important;
}