.Footer-Content{
    padding-left: 3rem;
    padding-right: 4rem;
    align-items: baseline;
    gap: 2rem;

}

.cardFooter{
    display: flex;
    gap: 2rem;
    align-items: baseline;
    margin-top: 1rem;
}

.subCardFooter{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-top: 1rem;
    cursor: pointer;
}

.productLink:hover{
color: #ff0000;
}
@media(max-width: 344px){
    .Footer-Content{
        padding-left: 0px;
    }
    .input-with-border{
        width: 240px;
    }
    .ticket-table-nav .ticket-count{
        gap: 59px;
        font-size: small;
        margin-bottom: 10px; 
    }
    .ticket-table-nav .ticket-buttons{
        display: block;
    }
    .ticket-buttons .search-input button {
        margin-top: 0px;
        margin-left: 200px;
    }
    .ticket-buttons .new-ticket {
        margin-left: 2px;
        width: 139px;
    }
    .ticket-buttons .filter-btn{
        margin-left: 140px;
        margin-top: -45px;
        width: 128px !important;
    }
    .ticket-buttons .search-input{
        width: 268px;
    }
    .severity{
        flex-direction: column;
        align-items: baseline;
    }
    .form-button{
        gap: 30px;
        font-size: small;
    }
    .left-img img {
        width: 100%;
    }
    .expiry-package{
        justify-content: space-between;
    }
}